import React, {FC} from 'react';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import {IntegrationBotProps} from '../../types';
import IntegrationSteps from '../integrationSteps';
import logo from '../../img/Hexometer-Logo-square.svg';
import plug from '../../img/plug.svg';
import {Translate} from '../translations';

const IntegrationBot: FC<IntegrationBotProps> = ({botLogo, botName, screenshot}) => (
  <Layout>
    <PageHero
      title={<Translate name={`${botName.replace('Pabbly Connect', 'Pabbly').toUpperCase()}_HERO_TITLE`} />}
      subtitle={<Translate name={`${botName.replace('Pabbly Connect', 'Pabbly').toUpperCase()}_HERO_SUBTITLE_TEXT`} />}
    />
    <Section>
      <div className="col-xs-12 col-lg-12 d-flex justify-content-center">
        <img src={logo} alt="Hexometer" width={120} height={120} className="mr-2" />
        <img src={plug} alt="plug" width={60} height={60} className="ml-2 mr-2 mt_30" />
        <img src={botLogo} alt={botName} width={120} height={120} className="ml-2" />
      </div>

      <div className="col-md-12 pl_50 pr_50 pt_50">
        <h3 className="f_size_24 f_600 mb_20 text-center t_color">
          <Translate name="INTEGRATION_STEPS_TITLE" />
        </h3>
        <p className="text-center">
          <Translate name="INTEGRATION_STEPS_TEXT" context={{botName}} />
        </p>
        <p>&nbsp;</p>
        <div className="pl_20 pr_20 d-lg-flex text-center justify-content-center">
          {botName !== 'Zapier' && botName !== 'Pabbly Connect' ? (
            <IntegrationSteps
              list={[
                {
                  text: (
                    <>
                      Log in at{' '}
                      <a href="https://dash.hexometer.com" no-index>
                        dash.hexometer.com
                      </a>
                    </>
                  ),
                  key: 'Log in',
                },
                {
                  text: 'Select monitoring property and go to the settings page',
                  key: 'Select property',
                },
                {
                  text: `Click Connect with ${botName} ${
                    botName === 'Telegram' || botName === 'Discord' ? 'and follow the instructions' : ''
                  }`,
                  key: 'Click connect',
                },
                {
                  text:
                    botName === 'Telegram' || botName === 'Discord'
                      ? ''
                      : botName === 'Trello'
                      ? 'Select the desired board and list to receive notifications'
                      : 'Select the desired channel to receive notifications',
                  key: 'Select channel',
                },
              ]}
            />
          ) : botName === 'Zapier' ? (
            <IntegrationSteps
              list={[
                {text: 'Log in to your Zapier account', key: 'Log in'},
                {
                  text: 'Create Zap with Hexometer and input your account credentials',
                  key: 'Create Zap',
                },
                {
                  text: 'Choose your property and event',
                  key: 'Select property',
                },
              ]}
            />
          ) : (
            <IntegrationSteps
              list={[
                {
                  text: (
                    <>
                      Log in at{' '}
                      <a href="https://dash.hexometer.com" no-index>
                        dash.hexometer.com
                      </a>
                    </>
                  ),
                  key: 'Log in',
                },
                {
                  text: 'Select monitoring property and go to the settings page',
                  key: 'Select property',
                },
                {text: `Get your API key`, key: 'Get key'},
                {
                  text: 'Use it in Pabbly Connect to receive Hexometer events',
                  key: 'Connect',
                },
              ]}
            />
          )}
        </div>
        {botName === 'Telegram' && (
          <p className="f_size_16 text-center" style={{marginTop: 0, color: '#263b5e'}}>
            Make sure to have the Telegram application installed on your device before clicking on connect.
          </p>
        )}
        <p>&nbsp;</p>
        {botName !== 'Zapier' && botName !== 'Pabbly Connect' && (
          <>
            <h3 className="f_size_24 f_600 mb_20 text-center t_color">
              <Translate name="INTEGRATION_TITLE" context={{botName}} />
            </h3>
            <p>
              <Translate name="INTEGRATION_UPPER_TEXT" />
            </p>
            {screenshot && (
              <p className="text-center">
                <img
                  src={screenshot}
                  alt={`${botName} notification sample`}
                  style={{maxHeight: '600px', maxWidth: '100%'}}
                />
              </p>
            )}
            <p>
              <Translate name="INTEGRATION_LOWER_TEXT" context={{botName}} />
            </p>
          </>
        )}
      </div>
    </Section>
  </Layout>
);

export default IntegrationBot;
