import React from 'react';
import './styles.scss';

const IntegrationSteps = (props: any) => (
  props.list.map((item: any, index: any) => (
    item.text && 
    <div key={item.key} className='step_container'>
      <div className='number'>{index+1}</div>
      <div className='d-none d-lg-block separator'></div>
      <p>{item.text}</p>
    </div>
  ))
);

export default IntegrationSteps;
